.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: var(--primary);
  color: var(--secondary-text);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  height: 60px; /* Add a fixed height */
  width: 100%;
}

.button {
  margin-left: 1rem;
  background-color: #333;
  color: white;
  font-size: 1.2rem;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.button:hover {
  background-color: #555;
}

.sidebar {
  margin-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: rgba(0,0,0,0.85);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 900;
}

.sidebar.active {
  transform: translateX(0);
}

.link {
  display: block; /* Add this line to ensure each link takes up the full width of the dropdown */
  padding: 8px 16px; /* Add this line to add padding around each link */
  text-decoration: none;
  font-size: 1.2rem;
  color: var(--accent); 
}

.link:hover {
  color: var(--secondary); /* Change the color to the secondary theme color */
  background-color: rgba(0, 0, 0, 0.1); /* Add this line to add a hover effect on the link's background */
}

.sidebar h1 {
  font-size: 1.4rem;
  color: white;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: color 0.2s;
}

.sidebar h1:hover {
  color: var(--secondary);
}

.desktopNav {
  display: flex;
  gap: 16px;
  align-items: center; /* Add this line */
}

.navItem {
  position: relative;
  cursor: pointer;
  display: inline-block; /* Add this line to make the navItem a proper container for the dropdown */
}

.dropdown {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 2px;
  background-color: var(--primary);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 8px 0;
  z-index: 1;
  min-width: 160px; /* Add this line to set a minimum width for the dropdown */
  white-space: nowrap; /* Add this line to prevent line breaks within the link text */
}

.navItem:hover .dropdown {
  display: block;
}

.desktopNav {
  display: none;
  margin-left: 16px; 
  align-items: center; 
}

@media (min-width: 768px) {
  .sidebar,
  .button {
    display: none;
  }
  .desktopNav {
    display: flex;
    gap: 16px;
  }
}

.logo {
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -14px;
  width: auto;
  height: auto;
  max-width: 40vw;
  max-height: 4vh;
}

@media (min-width: 768px) {
  .logo {
    position: static;
    transform: none;
    margin-right: 16px;
    margin-top: 0px;
  }
}

@media (max-width: 768px) {
  .logo {
      font-size: 1.5rem; 
  }
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (min-width: 768px) {
  .headerContent {
    justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  .desktopNav {
    display: flex;
    gap: 16px;
  }
}

.loginButton {
  margin-right: 20px; /* Adjust this value as needed */
}

/* New styles for the dropdown menu */
.userMenu {
  position: relative;
}

.dropdownMenu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
}

.dropdownItem {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #FFD700;
  font-weight: bold;
  transition: background-color 0.3s;
}

.dropdownItem:hover {
  background-color: #f1f1f1;
}

.modalContainer {
  background-color: #000; /* Dark background */
  color: #ff0; /* Yellow text */
  width: 300px; /* Adjust the width as needed */
  padding: 15px;
  border-radius: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Soft white shadow for contrast */
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 0%;
  backdrop-filter: blur(5px); /* Blurry background */
}

.closeButton {
  float: right;
  font-size: 30px;
  cursor: pointer;
  color: #fff; /* White X symbol */
}

/* Additional styles for input field, buttons, etc. */
.inputField {
  background-color: #333; /* Darker field background */
  border: 1px solid #555;
  color: #fff; /* White text */
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.submitButton {
  background-color: #FFD700; /* Yellow background */
  color: #000; /* Black text */
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.iconMenu {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .iconMenu {
    display: none;
  }
}

@media (min-width: 768px) {
  .desktopNav {
    display: flex;
    gap: 16px;
  }

  .iconMenu {
    display: flex;
    gap: 16px;
  }
}
