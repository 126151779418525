.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .privacy-policy h1 {
    font-size: 2.5em;
    color: #FFD700; /* Golden */
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
  }
  
  .privacy-policy h2 {
    font-size: 1.8em;
    color: #FFD700; /* Golden */
    margin-top: 30px;
  }
  
  .privacy-policy h3 {
    font-size: 1.4em;
    color: #FFD700; /* Golden */
  }
  
  .privacy-policy p, .privacy-policy ul {
    margin-bottom: 15px;
  }
  
  .privacy-policy ul {
    padding-left: 20px;
  }
  
  .privacy-policy li {
    margin-bottom: 5px;
  }