:root {
  --secondary-hover: #008080; /* Teal */
}

.container {
  display: inline-flex;
  background-color: rgba(0,0,0,0.75);
  padding: 24;
  border-radius: 24;
  width: 50vw;
  height: auto;
  box-shadow: 0px 5px 11px 2px rgba(0,0,0,0.7);
  align-items: center;
  flex-direction: column; /* Stack children vertically */
  border-radius: 12px; /* Adjust the value for more or less rounding */
}


.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  color: var(--primary-text);
}

.header {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--primary-text);
}

.question {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--primary-text);;
}

.content {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--primary-text);
}

.paragraph {
  margin-bottom: 1.5rem;
}

.list {
  padding-left: 1.5rem;
  list-style-type: none;
}

.list-item {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--primary-text);
  margin-bottom: 1rem;
}

.list-bullet {
  color: var(--primary-text);
  font-weight: 'bold';
  margin-right: '0.5rem';
}

.tile {
  flex: 0 1 auto;
  margin: 2%;
  width: calc(25% - 4%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .tile {
    width: 90%;
  }
  .container {
    width: auto;
  }
}

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.spinner-small {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes pulse-glow {
  0% {
    box-shadow: 0 0 5px 2px rgba(0, 255, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(0, 255, 0, 0.6);
  }
  100% {
    box-shadow: 0 0 5px 2px rgba(0, 255, 0, 0.3);
  }
}

.centered-image {
  max-width: 100%;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
  border-radius: 12px;
  border: 2px solid #00ff00;
  animation: pulse-glow 2s infinite ease-in-out;
}
.loading-message {
  margin-top: 20px;
  color: var(--accent);
  font-size: 16px;
  text-align: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.button {
  background-color: #FFD700; /* Golden */
  color: #333; /* Darker text for better contrast */
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin: 4px 2px;
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  box-shadow: 
    0 2px 4px rgba(0,0,0,0.2),
    0 -2px 4px rgba(255,255,255,0.2) inset,
    0 2px 4px rgba(0,0,0,0.2) inset;
}

.button:hover {
  background-color: #FFC400; /* Slightly darker gold */
  color: #000;
  box-shadow: 
    0 4px 8px rgba(0,0,0,0.3),
    0 -2px 4px rgba(255,255,255,0.2) inset,
    0 2px 4px rgba(0,0,0,0.2) inset;
  transform: translateY(-2px);
}

.button:active {
  background-color: #EAB000; /* Even darker gold when pressed */
  box-shadow: 
    0 1px 2px rgba(0,0,0,0.2),
    0 -1px 2px rgba(255,255,255,0.2) inset,
    0 1px 2px rgba(0,0,0,0.2) inset;
  transform: translateY(1px);
}

.tabs {
  display: flex;
  align-items: flex-end;
  background-color: transparent; /* Updated to transparent */
}

.tab-button {
  background-color: rgba(50, 50, 50, 0.7); /* dark gray, semi-transparent */
  color: white; /* Text color, for better contrast against dark background */
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
}

.tab-button:hover {
  background-color: rgba(70, 70, 70, 0.7); /* Slightly lighter gray on hover, still semi-transparent */
}

.tab-button.active {
  background-color: rgba(70, 70, 70, 0.7);
  border-bottom: 3px solid #FFD700;  /* Highlight color */
}

.tab-spacer {
  flex-grow: 1;
  border-bottom: 1px solid #ccc;
}

.tab-content {
  padding: 6px 12px;
  border-top: none;
  animation: fadeEffect 1s;
}

@keyframes fadeEffect {
  from {opacity: 0.4;}
  to {opacity: 1;}
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;  /* 16:9 Aspect Ratio */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 600px) {
  .video-container {
    width: 90%;       /* or any desired width */
    margin: 0 auto;  /* centers the container */
  }
}
