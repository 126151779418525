.galleryContainer {
    margin: 20px;
    padding: 20px;
  }
  

.imageGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust minmax values as needed */
    gap: 10px;
  }

.imageWrapper {
    flex: 1 1 240px; /* Grow to fill available space, with a base width of 240px */
    overflow: hidden; /* In case of large images */
  }
  
  
.image {
    width: 100%; /* Makes the image fill the container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensures the image covers the area nicely */
    border-radius: 12px; /* Adjust the value for more or less rounding */
  }
  
.image_preview {
    width: 50%;
    height: auto; /* This maintains the aspect ratio */
}

.preview_container {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 images per row */
  gap: 5px;
}

.preview_container img {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
